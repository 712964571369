<template>

    <div id="offers">

        <div class="offers-banner">

             <img class="banner-image" src="@/assets/images/banner.png" />

        </div>

        <div class="offers-content">

            <div class="offers text">

                <h2 class="brown-text">
                    Announcement
                </h2>

                <div class="text-container">

                    <p>Dearest Clients & Friends,</p>

                </div>

                <div class="text-container">

                    <p>Reena is now ready to get back to work.</p>

                    <p>Several circumstances have changed since the last time we welcomed you at the studio, and these are the terms that currently apply to new appointments, effective immediately:</p>

                    <p>● We do not accept walk-ins at the studio.</p>

                    <p>● We welcome you to book massage therapy sessions by appointment only.</p>

                    <p class="top-margin">Thanks and we look forward to welcoming you back!</p>

                    <p class="top-margin">Contact us for more information: book@rjmt.care</p>

                    <p class="top-margin">Book an appointment: <a href="https://rjmt.care/book">rjmt.care/book</a></p>

                </div>

            </div>

            <div class="offers buttons">

                <div class="button green-button" @click="goToBooking()">Book now</div>

                <a class="massage-book" href="https://www.massagebook.com/biz/RJMT#services"><img src="http://www.massagebook.com/home/img/getbutton/button-booknow.png" alt="Book Now on MassageBook.com!" border="0"></a>

                
            </div>

        </div>

        <div class="widgets">

            <h2 class="brown-text">More ways to connect.</h2>

            <p>Follow us on social media and never miss out on our latest offers!</p>

            <div class="social-media-widgets">

                <a class="social-media-widget" href="https://www.facebook.com/rjmt.care">

                    <img src="@/assets/icons/RJMT_fb.svg" />
   
                    
                </a>

                <a class="social-media-widget" href="https://www.instagram.com/rjmt.care">

                    <img src="@/assets/icons/RJMT_insta.svg" />

                </a>

            </div>


        </div>




    </div>



</template>

<script>

export default {
    name: 'Offers',
    methods: {
        goToBooking() {
            this.$router.replace('/book')
        },
        goToGiftCards(url) {
            window.open(url, '_blank').focus();
        }
    }
}



</script>

<style scoped>

#offers {
    width: 100%;
    max-width: 1200px;
    box-sizing: border-box;
    margin: auto;
    margin-top: 100px;  
    box-sizing: border-box;
    /* padding: 75px 10% 100px 10%; */
    background-color: #C5E0AC;  
}

.offers-banner {
    width: 100%;
}

.offers-content {
    margin-top: -10px;
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 20px;
}

.offers {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.buttons {
    justify-content: center;
    align-items: center;
}

.button {
    text-transform: uppercase;
    font-weight: 600;
    align-items: center;
    padding: 20px 40px 20px 40px;
    box-sizing: border-box;
    border-radius: 5px;
    /* align-self: center; */
    font-size: 17px;
    letter-spacing: 1px;
    width: max-content;
    justify-content: center;
    margin-top: 50px;
    cursor: pointer;
    /* margin-right: 50px; */
}

.green-button {
    border: 1px solid #1E9656;
    background-color: #1E9656;
    color: white;
}

.green-button:hover {
    opacity: 0.8;
    transition: 0.2s; 
}

h2 {
    font-size: 50px;
    font-weight: 200;
    margin: 0px 0px 30px 0px;
    display: flex;
    align-items: center;
}

h2 img {
    margin-left: 10px;
    height: 50px;
    width: 50px;
}

.text-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

p {
    margin: 5px 0px 5px 0px;
    font-size: 20px;
}

.massage-book {
    margin-top: 50px;
}

.widgets {
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.social-media-widgets {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.social-media-widget {
    width: 47.5%;
    display: flex;
    margin-top: 30px;
    justify-content: center;
    align-items: center
}

.social-media-widget img {
    width: 50px;
    height: 50px;
}

.top-margin {
    margin-top: 20px;
}

@media(max-width: 750px) {
    .offers {
        width: 100%;
    }

    .buttons {
        align-items: flex-start;
    }

    .social-media-widget {
        justify-content: flex-start;
        /* width: 100%; */
    }
}

</style>